<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg"
    v-if="preferences && preferences.length"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_company-preferences-category-help-online',
          query: { slug: helpSlug },
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_company-preferences' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ preferences[0].preference_category.name }}
        </div>
        <!-- For custom categories -->
        <template
          v-slot:right
          v-if="customPreferenceCategories.includes(Number(routeParam))"
        >
          <button @click="setData()">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (pref) =>
            !customPreferenceCategories.includes(pref.preference_category_id)
        )"
        :key="i"
      >
        <router-link
          :to="{
            name: 'r_set-company-preference',
            params: { preferenceId: preference.id },
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              preference.name
            }}</label>
            <p
              class="text-label ellipsis"
              v-if="preference.preference_values.length"
            >
              <span
                v-for="(preferenceValue, index) in preference.preference_values"
                :key="index"
              >
                {{
                  displayLabelName(
                    "preferences",
                    "preference-category",
                    preferenceValue.label
                      ? preferenceValue.label
                      : ["DEFAULT_SLOT", "AVAILABLE_SLOTS"].includes(
                          preference.preference
                        )
                      ? preferenceValueLabels.DEFAULT_SLOTS[
                          preferenceValue.value
                        ]
                      : preferenceValue.value
                  )
                }}{{
                  preference.preference_values.length - 1 !== index ? ", " : ""
                }}
                {{
                  preference.preference === "TIME_FORMAT"
                    ? preferenceValue.value.charAt(0) === "H"
                      ? "(24h)"
                      : "(AM/PM)"
                    : ""
                }}</span
              >
            </p>
            <p class="text-label ellipsis" v-else>
              <span>
                {{
                  displayLabelName(
                    "preferences",
                    "preference-category",
                    "not-selected"
                  )
                }}</span
              >
            </p>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </router-link>
      </li>

      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id === preferenceCategoryIds.gdprRules
        )"
        :key="i"
      >
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            preference.name
          }}</label>

          <div class="company-preferences-input-section">
            <button
              class="company-preferences-btn decrease-capacity"
              @click="decreaseDays()"
            >
              -
            </button>
            <div class="company-preferences-input-wrapper clebex-section-input">
              <input type="text" v-model="numberDays" @input="preventText" />
            </div>
            <button
              class="company-preferences-btn increase-capacity"
              @click="increaseDays()"
            >
              +
            </button>
          </div>
        </div>
      </li>

      <li
        class="clebex-item-section-item full-right-underline"
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id ===
            preferenceCategoryIds.userFormTemplate
        )"
        :key="i"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  v-model="userFormTemplate"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
      </li>
      <li
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id === preferenceCategoryIds.counter
        )"
        :key="i"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label for="name" class="clebex-section-input-label">
              {{ preference.name }}
            </label>
            <input
              v-if="preference.preference === 'ELAPSED_TIME_TRIGGER'"
              type="number"
              v-model="elapsedTimeTrigger"
              min="0"
              @change="changeTimeTrigger(preference.preference)"
            />
            <input
              v-if="preference.preference === 'MINOR_TIME_TRIGGER'"
              type="number"
              v-model="minorTimeTrigger"
              min="0"
              max="100"
              @change="changeTimeTrigger(preference.preference)"
            />
          </div>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section" v-if="routeParam === '10'">
      <li
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id === preferenceCategoryIds.plan &&
            item.preference !== 'SHOW_CAMERA_BUTTON' &&
            item.preference !== 'PLAN_SORT_ALPHABETICALLY' &&
            item.preference !== 'PLAN_SORT_BY_TREE_STRUCTURE'
        )"
        :key="i"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label for="name" class="clebex-section-input-label">
              {{ preference.name }}
            </label>
            <input
              v-if="preference.preference === 'PARTIALLY_BUSY_PERCENTAGE'"
              type="number"
              v-model="partiallyBusyPercentage"
              min="0"
              max="100"
              @change="changePercentage(preference.preference)"
            />
            <input
              v-if="preference.preference === 'BUSY_PERCENTAGE'"
              type="number"
              v-model="busyPercentage"
              min="0"
              max="100"
              @change="changePercentage(preference.preference)"
            />
            <input
              v-if="preference.preference === 'WORKING_HOURS_FROM'"
              type="text"
              v-model="workingHoursFrom"
              @change="changePercentage(preference.preference)"
              disabled
            />
            <input
              v-if="preference.preference === 'WORKING_HOURS_TO'"
              type="text"
              v-model="workingHoursTo"
              @change="changePercentage(preference.preference)"
              disabled
            />
          </div>
        </div>
      </li>
    </ul>
    <!-- Sort plan - START -->
    <ul class="clebex-item-section" v-if="routeParam === '10'">
      <li
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id === preferenceCategoryIds.plan &&
            (item.preference === 'PLAN_SORT_ALPHABETICALLY' ||
              item.preference === 'PLAN_SORT_BY_TREE_STRUCTURE')
        )"
        :key="i"
        class="clebex-item-section-item full-right-underline"
      >
        <div
          class="clebex-item-content-wrapper"
          v-if="preference.preference === 'PLAN_SORT_ALPHABETICALLY'"
        >
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  v-model="sortAlphabetically"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
        <div
          class="clebex-item-content-wrapper"
          v-if="preference.preference === 'PLAN_SORT_BY_TREE_STRUCTURE'"
        >
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  v-model="sortByTreeStructure"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
        <!-- <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label for="name" class="clebex-section-input-label">
              {{ preference.name }}
            </label>
            <input
              v-if="preference.preference === 'PLAN_SORT_ALPHABETICALLY'"
              type="number"
              v-model="partiallyBusyPercentage"
              min="0"
              max="100"
              @change="changePercentage(preference.preference)"
            />
            <input
              v-if="preference.preference === 'PLAN_SORT_BY_TREE_STRUCTURE'"
              type="number"
              v-model="busyPercentage"
              min="0"
              max="100"
              @change="changePercentage(preference.preference)"
            />
          </div>
        </div> -->
      </li>
    </ul>
    <!-- Sort plan - END -->
    <!-- Show camera button - START -->
    <ul class="clebex-item-section" v-if="routeParam === '10'">
      <li
        v-for="(preference, i) in preferences.filter(
          (item) =>
            item.preference_category_id === preferenceCategoryIds.plan &&
            item.preference === 'SHOW_CAMERA_BUTTON'
        )"
        :key="i"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  v-model="showCameraButton"
                  :id="preference.id"
                  :value="preference.id"
                  :name="preference.id"
                />
                <label :for="preference.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ preference.name }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <!-- Show camera button - END -->
    <ul class="clebex-item-section" v-if="routeParam === '10'">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <select-time-picker-range
            :select-hour-fn="selectHour"
            :selected-hours="[workingHoursFrom, workingHoursTo]"
          />
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import {
  preferenceValueLabels,
  preferenceCategoryIds,
  preferenceCategoryIdsHelpOnline,
} from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "PreferenceCategory",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
  },
  data() {
    return {
      preferenceCategoryIds: preferenceCategoryIds,
      preferenceCategoryIdsHelpOnline: preferenceCategoryIdsHelpOnline,
      customPreferenceCategories: [8, 9, 10, 12],
      bulkUpdateCategories: [9, 10, 12],
      numberDays: 0,
      userFormTemplate: [],
      url: "",
      partiallyBusyPercentage: 0,
      busyPercentage: 0,
      elapsedTimeTrigger: 0,
      minorTimeTrigger: 0,
      workingHoursFrom: null,
      workingHoursTo: null,
      preferenceValueLabels: preferenceValueLabels,
      helpSlug: preferenceCategoryIdsHelpOnline[this.$route.params.categoryId],
      showCameraButton: false,
      sortAlphabetically: true,
      sortByTreeStructure: false,
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"]),
    routeParam() {
      const { categoryId } = this.$route.params;
      return categoryId;
    },
  },
  methods: {
    ...mapActions("companyPreferences", [
      "setPreferenceUpdateData",
      "getCompanyPreferences",
    ]),
    getPreferences() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getCompanyPreferences(this.routeParam)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.getInitialValues();
        });
    },
    preventText(e) {
      if (/[^0-9]/g.test(e.target.value)) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
      }
    },
    increaseDays() {
      if (Number(this.numberDays) || Number(this.numberDays) === 0) {
        this.numberDays = Number(this.numberDays) + 1;
      }
    },
    decreaseDays() {
      if (Number(this.numberDays - 1) >= 0) {
        this.numberDays = Number(this.numberDays) - 1;
      }
    },
    setData() {
      let preferenceUpdateData = {};
      let updateData = [];
      let val = "";
      switch (Number(this.routeParam)) {
        case this.preferenceCategoryIds.gdprRules:
          preferenceUpdateData.values = [
            {
              value: this.numberDays,
              id: this.preferences.find((el) => el.preference === "GDPR_RULES")
                .id,
            },
          ];
          break;
        case this.preferenceCategoryIds.userFormTemplate:
          //update multiple preferences, using update bulk
          this.preferences.forEach((pref) => {
            updateData.push({
              preference: pref.preference,
              values: [
                {
                  value: Object.values(this.userFormTemplate).includes(
                    pref.preference_values[0].preference_id
                  )
                    ? 1
                    : 0,
                  id: pref.preference_values[0].id,
                },
              ],
            });
          });
          preferenceUpdateData.preferences = updateData;
          break;
        case this.preferenceCategoryIds.plan:
          //update multiple preferences, using update bulk
          this.preferences.forEach((pref) => {
            if (pref.preference === "PARTIALLY_BUSY_PERCENTAGE") {
              val = this.partiallyBusyPercentage;
            } else if (pref.preference === "BUSY_PERCENTAGE") {
              val = this.busyPercentage;
            } else if (pref.preference === "WORKING_HOURS_FROM") {
              val = this.workingHoursFrom;
            } else if (pref.preference === "WORKING_HOURS_TO") {
              val = this.workingHoursTo;
            } else if (pref.preference === "SHOW_CAMERA_BUTTON") {
              val = String(Number(this.showCameraButton));
            } else if (pref.preference === "PLAN_SORT_ALPHABETICALLY") {
              val = String(Number(this.sortAlphabetically));
            } else if (pref.preference === "PLAN_SORT_BY_TREE_STRUCTURE") {
              val = String(Number(this.sortByTreeStructure));
            }

            updateData.push({
              preference: pref.preference,
              values: [
                {
                  value: val,
                },
              ],
            });
          });
          preferenceUpdateData.preferences = updateData;
          break;
        case this.preferenceCategoryIds.counter:
          //update multiple preferences, using update bulk
          this.preferences.forEach((pref) => {
            if (pref.preference === "ELAPSED_TIME_TRIGGER") {
              val = this.elapsedTimeTrigger;
            } else if (pref.preference === "MINOR_TIME_TRIGGER") {
              val = this.minorTimeTrigger;
            }
            updateData.push({
              preference: pref.preference,
              values: [
                {
                  value: val,
                },
              ],
            });
          });
          preferenceUpdateData.preferences = updateData;
          break;

        default:
          break;
      }
      this.setPreferenceUpdateData(preferenceUpdateData);
      this.updatePreference();
    },
    updatePreference() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.bulkUpdateCategories.includes(Number(this.routeParam))) {
        this.url = `${apiEndpoints.company.companyPreferences}/bulk`;
      } else {
        this.url = `${apiEndpoints.company.companyPreferences}/${this.preferences[0].id}`;
      }
      httpServiceAuth
        .put(this.url, this.preferenceUpdateData)
        .then((response) => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            (obj) => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$router.push({ name: "r_company-preference-category" });
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    },
    getInitialValues() {
      if (this.preferences) {
        this.preferences.forEach((pref) => {
          switch (pref.preference_category_id) {
            case this.preferenceCategoryIds.gdprRules:
              this.numberDays = this.preferences[0].preference_values[0].value;
              break;
            case this.preferenceCategoryIds.userFormTemplate:
              if (
                pref.preference_values.length &&
                pref.preference_values[0].value == 1
              ) {
                this.userFormTemplate.push(
                  pref.preference_values[0].preference_id
                );
              }
              break;
            case this.preferenceCategoryIds.plan:
              if (pref.preference_values.length) {
                if (pref.preference === "PARTIALLY_BUSY_PERCENTAGE") {
                  this.partiallyBusyPercentage =
                    pref.preference_values[0].value;
                } else if (pref.preference === "BUSY_PERCENTAGE") {
                  this.busyPercentage = pref.preference_values[0].value;
                } else if (pref.preference === "WORKING_HOURS_FROM") {
                  this.workingHoursFrom = pref.preference_values[0].value;
                } else if (pref.preference === "WORKING_HOURS_TO") {
                  this.workingHoursTo = pref.preference_values[0].value;
                } else if (pref.preference === "SHOW_CAMERA_BUTTON") {
                  this.showCameraButton = Boolean(
                    Number(pref.preference_values[0].value)
                  );
                } else if (pref.preference === "PLAN_SORT_ALPHABETICALLY") {
                  this.sortAlphabetically = Boolean(
                    Number(pref.preference_values[0].value)
                  );
                } else if (pref.preference === "PLAN_SORT_BY_TREE_STRUCTURE") {
                  this.sortByTreeStructure = Boolean(
                    Number(pref.preference_values[0].value)
                  );
                }
              }
              break;
            case this.preferenceCategoryIds.counter:
              if (pref.preference_values.length) {
                if (pref.preference === "ELAPSED_TIME_TRIGGER") {
                  this.elapsedTimeTrigger = pref.preference_values[0].value;
                } else if (pref.preference === "MINOR_TIME_TRIGGER") {
                  this.minorTimeTrigger = pref.preference_values[0].value;
                }
              }
              break;

            default:
              break;
          }
        });
      }
    },
    changePercentage(preference) {
      // If user adds percentage manually we validate here if its in 0-100 range
      if (preference === "PARTIALLY_BUSY_PERCENTAGE") {
        this.partiallyBusyPercentage =
          this.partiallyBusyPercentage > 100
            ? 100
            : this.partiallyBusyPercentage;
        this.partiallyBusyPercentage =
          this.partiallyBusyPercentage < 0 ? 0 : this.partiallyBusyPercentage;
      } else if (preference === "BUSY_PERCENTAGE") {
        this.busyPercentage =
          this.busyPercentage > 100 ? 100 : this.busyPercentage;
        this.busyPercentage = this.busyPercentage < 0 ? 0 : this.busyPercentage;
      }
    },
    changeTimeTrigger(preference) {
      // If user adds percentage manually we validate here if its in positive range
      if (preference === "ELAPSED_TIME_TRIGGER") {
        this.elapsedTimeTrigger =
          this.elapsedTimeTrigger >= 0 ? this.elapsedTimeTrigger : 0;
      } else if (preference === "MINOR_TIME_TRIGGER") {
        this.minorTimeTrigger =
          this.minorTimeTrigger >= 0 ? this.minorTimeTrigger : 0;
      }
    },
    selectHour(hours) {
      this.workingHoursFrom = hours[0];
      this.workingHoursTo = hours[1];
    },
  },
  created() {
    this.getPreferences();
  },
  watch: {
    routeParam(value) {
      if (value) {
        this.getPreferences();
        this.helpSlug =
          preferenceCategoryIdsHelpOnline[this.$route.params.categoryId];
        this.getHelpOnline(this.helpSlug);
      }
    },
    sortAlphabetically() {
      this.sortByTreeStructure = !this.sortAlphabetically;
    },
    sortByTreeStructure() {
      this.sortAlphabetically = !this.sortByTreeStructure;
    },
  },
};
</script>
